<template>
  <div class="container">
    <md-card class="dash-content">
      <div class="header-wrapper">
        <PageHeader title="Create Product" />
        <div>
          <md-button :to="{ name: 'products' }" class="md-primary">
            Back
          </md-button>
        </div>
      </div>

      <div>
        <form novalidate class="md-layout" @submit.prevent="validateUser">
          <md-field :class="getValidationClass('title')">
            <label>Product Title</label>
            <md-input v-model="form.title" required></md-input>
            <span class="md-error" v-if="!$v.form.title.required"
              >Title is required</span
            >
            <span class="md-error" v-if="!$v.form.title.minLength"
              >Title too Short</span
            >
          </md-field>

          <md-field :class="getValidationClass('description')">
            <label>Product Description</label>
            <md-textarea v-model="form.description" required></md-textarea>
            <span class="md-error" v-if="!$v.form.description.required"
              >Description is required</span
            >
            <span class="md-error" v-if="!$v.form.title.minLength"
              >Description too Short</span
            >
          </md-field>

          <md-field :class="getValidationClass('title')">
            <label>Product Brand</label>
            <md-input v-model="form.brand"></md-input>
          </md-field>

          <!-- <div>
            <md-field
              v-if="metadata"
              :class="getValidationClass('category_id')"
            >
              <label for="category">Category</label>
              <md-select
                v-model="form.category_id"
                name="category"
                id="category"
              >
                <md-option value="" disabled>Select Category</md-option>
                <md-option
                  v-for="(category, id) in metadata.categories"
                  :key="id"
                  :value="id"
                  >{{ category }}</md-option
                >
              </md-select>
              <span class="md-error" v-if="!$v.form.category_id.required"
                >Category is required</span
              >
            </md-field>
          </div> -->

          <md-autocomplete
            :md-options="metadata.categories"
            v-model="selectedItem"
            @md-changed="setVal"
          >
            <label>Select Category</label>
            <template slot="md-autocomplete-item" slot-scope="{ item }">{{
              item.name
            }}</template>

            <template slot="md-autocomplete-empty" slot-scope="{ term }">
              No colors matching "{{ term }}"
            </template>
          </md-autocomplete>

          <div class="feature-attributes">
            <div class="feature-header">
              <div class="wrapper">
                <div class="attr">
                  <h4>Features</h4>
                </div>
                <div class="val">
                  <h4>Value</h4>
                </div>
              </div>
            </div>
            <div
              class="wrapper"
              v-for="(attr, i) in form.selectedAttributes"
              :key="i"
            >
              <div class="attr">
                <!-- <md-field v-if="metadata">
                  <select
                    :name="`selectedAttributes[${i}][key]`"
                    id="category"
                    v-model="attr.key"
                    class="custom-input"
                  >
                    <option value="" disabled>Select Attribute</option>
                    <option
                      v-for="(attribute, id) in metadata.attributes"
                      :key="id"
                      :value="id"
                    >
                      {{ attribute }}
                    </option>
                  </select>
                </md-field> -->

                <md-field v-if="metadata">
                  <md-autocomplete
                    :md-options="metadata.attributes"
                    v-model="selectedAttr"
                    @md-changed="(v) => setAttr(v, i)"
                  >
                    <label>Select Attribute</label>
                    <template
                      slot="md-autocomplete-item"
                      slot-scope="{ item }"
                      >{{ item.name }}</template
                    >
                    <template
                      slot="md-autocomplete-empty"
                      slot-scope="{ term }"
                    >
                      No colors matching "{{ term }}"
                    </template>
                  </md-autocomplete>
                </md-field>
              </div>

              <div class="val">
                <md-field>
                  <input
                    class="custom-input"
                    v-model="attr.value"
                    :name="`selectedAttributes[${i}][value]`"
                    id="value"
                  />
                </md-field>
              </div>
              <div>
                <md-button
                  v-if="i != 0"
                  class="md-primary btn-sm"
                  @click="removeFeature(i)"
                >
                  Remove
                </md-button>
              </div>
            </div>

            <md-button
              class="md-primary btn-sm btn-outlined"
              @click="addFeature"
            >
              Add Feature
            </md-button>
          </div>

          <md-field v-if="metadata">
            <label for="tags">Tags</label>
            <md-select
              v-model="form.selectedTags"
              name="tags"
              id="tags"
              multiple
            >
              <md-option value="" disabled>Select Tags</md-option>
              <md-option
                v-for="(tag, id) in metadata.tags"
                :key="tag"
                :value="id"
                >{{ tag }}</md-option
              >
            </md-select>
          </md-field>

          <md-field :class="getValidationClass('price')">
            <label>Product Price</label>
            <md-input v-model="form.price" required type="number"></md-input>
            <span class="md-error" v-if="!$v.form.price.required"
              >Price is required</span
            >
          </md-field>

          <md-field>
            <label>Product Old Price</label>
            <md-input v-model="form.old_price" type="number"></md-input>
          </md-field>

          <md-field>
            <label>Quantity</label>
            <md-input v-model="form.quantity" type="number" required></md-input>
            <span class="md-error" v-if="!$v.form.quantity.required"
              >Quantity is required</span
            >
          </md-field>

          <md-field v-if="metadata">
            <label for="delivery_location">Delivery Location* </label>
            <md-select
              v-model="form.delivery_location"
              name="delivery_location"
              id="delivery_location"
              multiple
            >
              <md-option value="" disabled>Select Delivery Location</md-option>
              <md-option
                v-for="state in metadata.states"
                :key="state"
                :value="state"
                >{{ state }}</md-option
              >
            </md-select>
          </md-field>

          <md-field>
            <label>Preview</label>
            <input type="file" @change="handleFiles" name="images[]" multiple />
          </md-field>
          <br />

          <mdc-button :loading="loading">Create Product</mdc-button>
        </form>
      </div>
    </md-card>
  </div>
</template>

<script>
import { validationMixin } from "vuelidate";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapGetters } = createNamespacedHelpers("product");
export default {
  mixins: [validationMixin],
  data: () => ({
    form: {
      title: "",
      description: "",
      category_id: null,
      selectedAttributes: [
        {
          key: "",
          value: "",
        },
      ],
      selectedTags: [],
      images: [],
    },
    loading: false,
    metadata: null,
  }),
  validations: {
    form: {
      title: {
        required,
        minLength: minLength(3),
      },
      description: {
        required,
        minLength: minLength(10),
      },
      category_id: {
        required,
      },
      price: {
        required,
      },
      quantity: {
        required,
      },
    },
  },
  methods: {
    ...mapActions(["createProduct", "getMetaData"]),
    async validateUser() {
      this.$v.$touch();

      if (!this.$v.$invalid) {
        let data = { ...this.form };
        data.selectedAttributes = JSON.stringify(data.selectedAttributes);
        data.delivery_location = JSON.stringify(data.delivery_location);
        let tags = data.selectedTags.map((id) => {
          return { id };
        });
        data.selectedTags = JSON.stringify(tags);
        try {
          this.loading = true;
          await this.createProduct(data);
          this.toast("success", "Product Created");
          this.$router.push({ name: "products" });
          this.loading = false;
        } catch (err) {
          this.loading = false;
          //   this.parseError(err);
          this.toast("error", "Opps, Something went wrong ");
        }
      }
    },
    setVal(val) {
      const item = this.metadata.categories.find(
        (i) => i.name.toLowerCase() == val.toLowerCase()
      );
      this.form.category_id = item.id;
    },
    setAttr(val, i) {
      console.log(val, i);
      console.log(this.form.selectedAttributes);
      const item = this.metadata.attributes.find(
        (i) => i.name.toLowerCase() == val.toLowerCase()
      );
      this.form.selectedAttributes[i].key = item.id;
    },
    categoriesArray(categories) {
      return Object.entries(categories).map(([id, name]) => ({
        id,
        name,
      }));
    },
    attributesArray(attr) {
      return Object.entries(attr).map(([id, name]) => ({
        id,
        name,
      }));
    },
    addFeature() {
      this.form.selectedAttributes.push({
        key: "",
        value: "",
      });
    },
    removeFeature(id) {
      this.form.selectedAttributes.splice(id, 1);
    },
    async getData() {
      this.fetching = true;
      const res = await this.getMetaData();
      this.fetching = false;
      this.metadata = res.data;
      this.metadata.categories = this.categoriesArray(res.data.categories).sort(
        (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
      this.metadata.attributes = this.attributesArray(res.data.attributes).sort(
        (a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())
      );
    },
    handleFiles(e) {
      let files = e.target.files;

      for (let i = 0; i < files.length; i++) {
        this.form.images.push(files[i]);
      }
    },
  },
  mounted() {
    this.getData();

    this.$on("FileList", this.handleFiles);
  },
  computed: {
    selectedItem: {
      get() {
        return this.value;
      },
      set(item) {
        this.form.category_id;
        this.$emit("input", item.id);
      },
    },
    selectedAttr: {
      get() {
        return this.value;
      },
      set(item) {
        //this.form.category_id;
        this.$emit("input", item.id);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.feature-attributes {
  width: 100%;
  .wrapper {
    display: flex;
    align-items: center;
    gap: 20px;

    .val {
      flex: 1;
    }

    .attr {
      width: 25%;
    }
  }
}
</style>
<style>
.md-list-item-text {
  position: relative !important;
}
</style>
